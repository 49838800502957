// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "../stylesheets/application";
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import "channels"
import "bootstrap"
import "admin-lte"
import "@fortawesome/fontawesome-free/js/all"

document.addEventListener("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip();

  let alertP = $('#toastsContainerTopRight');

  $('#toastsContainerTopRight button').on('click', (e) => {
    alertP.remove();
  });
});

Rails.start();
Turbolinks.start();
ActiveStorage.start();
